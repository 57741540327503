<template>
  <v-container class="recurring-pattern-template px-0 py-0">
    <v-tabs
      height="32"
      color="cyan"
      v-model.trim="pattern.type"
      background-color="transparent"
    >
      <v-tab href="#daily" class="font-size-14 font-weight-500">Daily</v-tab>
      <v-tab href="#weekly" class="font-size-14 font-weight-500">Weekly</v-tab>
      <v-tab href="#monthly" class="font-size-14 font-weight-500"
        >Monthly</v-tab
      >
      <v-tabs-items v-model.trim="pattern.type">
        <v-tab-item :value="'daily'" class="mt-4">
          <v-radio-group
            mandatory
            class="mt-0"
            v-model.trim="pattern.daily.recurring_pattern"
            hide-details
            :disabled="loading || pattern.type != 'daily'"
          >
            <v-radio value="2" color="cyan">
              <template v-slot:label>
                <table>
                  <tr>
                    <td>Every</td>
                    <td>
                      <v-text-field
                        dense
                        filled
                        flat
                        v-mask="'###'"
                        hide-details
                        solo
                        :rules="
                          pattern.daily.recurring_pattern != 2
                            ? []
                            : [
                                validateRules.required(
                                  pattern.daily.day_count,
                                  'Day'
                                ),
                              ]
                        "
                        color="cyan"
                        :loading="loading"
                        :disabled="
                          loading || pattern.daily.recurring_pattern != 2
                        "
                        v-model.trim="pattern.daily.day_count"
                        class="mx-2 min-width-100px max-width-100px"
                      ></v-text-field>
                    </td>
                    <td>day(s)</td>
                  </tr>
                </table>
              </template>
            </v-radio>
            <v-radio label="Weekday" color="cyan" value="1"></v-radio>
          </v-radio-group>
        </v-tab-item>
        <v-tab-item :value="'weekly'" class="mt-4">
          <v-radio-group
            mandatory
            class="mt-0"
            v-model.trim="pattern.weekly.recurring_pattern"
            hide-details
            :disabled="loading || pattern.type != 'weekly'"
          >
            <v-radio value="1" color="cyan">
              <template v-slot:label>
                <table>
                  <tr>
                    <td>Every</td>
                    <td>
                      <v-text-field
                        dense
                        filled
                        flat
                        v-mask="'###'"
                        hide-details
                        solo
                        color="cyan"
                        :rules="[
                          validateRules.required(
                            pattern.weekly.week_count,
                            'Count'
                          ),
                        ]"
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="pattern.weekly.week_count"
                        class="mx-2 min-width-100px max-width-100px"
                      ></v-text-field>
                    </td>
                    <td>week(s) on</td>
                  </tr>
                </table>
              </template>
            </v-radio>
          </v-radio-group>
          <v-row>
            <v-col
              :md="showCalendar ? 3 : 2"
              v-for="(weekday, index) in jobWeekDays"
              :key="index"
            >
              <v-checkbox
                color="cyan"
                :rules="[validateRules.required(pattern.weekly.weeks, 'Weeks')]"
                v-model.trim="pattern.weekly.weeks"
                :label="showCalendar ? weekday.short_name : weekday.long_name"
                :value="weekday.id"
                hide-details
                class="mt-0"
                :disabled="loading || pattern.type != 'weekly'"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'monthly'" class="mt-4">
          <v-radio-group
            mandatory
            class="mt-0"
            v-model.trim="pattern.monthly.recurring_pattern"
            hide-details
            :disabled="loading || pattern.type != 'monthly'"
          >
            <v-radio
              value="1"
              color="cyan"
              class="align-items-start radio-icon-start"
            >
              <template v-slot:label>
                <!-- <perfect-scrollbar
                  :options="{ suppressScrollY: true, useBothWheelAxes: true }"
                  class="scroll position-relative"
                  :style="verticalScrollbar"
                > -->

                <div class="d-flex flex-wrap align-center">
                  <span>Day</span>
                  <span class="mb-1">
                    <v-text-field
                      dense
                      filled
                      flat
                      v-mask="'###'"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 1
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.day_count,
                                'Day'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 1
                      "
                      v-model.trim="pattern.monthly.day_count"
                      class="mx-2 min-width-100px max-width-100px"
                    ></v-text-field>
                  </span>
                  <span>of every</span>
                  <span class="mb-1">
                    <v-text-field
                      dense
                      filled
                      flat
                      v-mask="'###'"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 1
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.day_month_count,
                                'Month'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 1
                      "
                      v-model.trim="pattern.monthly.day_month_count"
                      class="mx-2 min-width-100px max-width-100px"
                    ></v-text-field>
                  </span>
                  <span>month(s)</span>
                </div>
                <!-- </perfect-scrollbar> -->
              </template>
            </v-radio>
            <v-radio
              value="2"
              color="cyan"
              class="align-items-start radio-icon-start"
            >
              <template v-slot:label>
                <!-- <perfect-scrollbar
                  :options="{ suppressScrollY: true, useBothWheelAxes: true }"
                  class="scroll position-relative"
                  :style="verticalScrollbar"
                > -->
                <div class="d-flex flex-wrap align-center">
                  <span>The</span>
                  <span class="mb-1">
                    <v-select
                      :items="jobDayOfMonths"
                      dense
                      filled
                      flat
                      item-text="value"
                      item-value="id"
                      label="Day of Month"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 2
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.week_day,
                                'Week'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 2
                      "
                      v-model.trim="pattern.monthly.week_day"
                      class="mx-2 width-150px"
                    >
                    </v-select>
                  </span>
                  <span class="mb-1">
                    <v-select
                      :items="jobWeekDays"
                      dense
                      filled
                      flat
                      item-text="long_name"
                      item-value="id"
                      label="Weekday"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 2
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.week_count,
                                'Count'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 2
                      "
                      v-model.trim="pattern.monthly.week_count"
                      class="mx-2 width-150px"
                    >
                    </v-select>
                  </span>
                  <span>of every</span>
                  <span class="mb-1">
                    <v-text-field
                      dense
                      filled
                      flat
                      v-mask="'###'"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 2
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.month_count,
                                'Month'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 2
                      "
                      v-model.trim="pattern.monthly.month_count"
                      class="mx-2 width-100px"
                    ></v-text-field>
                  </span>
                  <span>month(s)</span>
                </div>
                <!-- </perfect-scrollbar> -->
              </template>
            </v-radio>
          </v-radio-group>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import JobRecurringPatternMixin from "@/core/lib/job/job.recurring.pattern.mixin";
import { JobEventBus } from "@/core/lib/job/job.lib";

export default {
  name: "recurring-pattern-template",
  mixins: [JobRecurringPatternMixin, ValidationMixin],
  props: {
    showCalendar: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeout: null,
      timeoutLimit: 500,
      pattern: {
        type: "daily",
        daily: {
          day_count: "1",
          recurring_pattern: "2",
        },
        weekly: {
          recurring_pattern: "1",
          week_count: "1",
          weeks: [1],
        },
        monthly: {
          recurring_pattern: "1",
          day_count: "1",
          week_day: 1,
          week_count: 1,
          day_month_count: "1",
          month_count: "1",
        },
      },
    };
  },
  watch: {
    "pattern.type"(param) {
      const _this = this;
      if (param.toString() === "daily") {
        _this.pattern.daily.day_count = "1";
        _this.pattern.daily.recurring_pattern = "2";
      }
      if (param.toString() === "weekly") {
        _this.pattern.weekly.week_count = "1";
        _this.pattern.weekly.weeks = [1];
        _this.pattern.weekly.recurring_pattern = "2";
      }
      if (param.toString() === "monthly") {
        _this.pattern.monthly.recurring_pattern = "1";
        _this.pattern.monthly.day_count = "1";
        _this.pattern.monthly.week_day = 1;
        _this.pattern.monthly.week_count = 1;
        _this.pattern.monthly.day_month_count = "1";
        _this.pattern.monthly.month_count = "1";
      }
    },
    pattern: {
      deep: true,
      immediate: true,
      handler(param) {
        const pattern = this.lodash.cloneDeep(param);

        if (pattern.type == "daily") {
          if (pattern.daily.recurring_pattern == 1) {
            pattern.daily.day_count = 0;
          }
        }

        this.$nextTick(() => {
          this.emitEvent();
        });
      },
    },
  },
  methods: {
    emitEvent() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        JobEventBus.$emit("update:recurring-pattern", _this.pattern);
      }, _this.timeoutLimit);
    },
  },
  computed: {
    verticalScrollbar() {
      return "max-width: " + (this.showCalendar ? "60vh;" : "100%;");
    },
  },
};
</script>
