<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'job-create'"
    v-if="getPermission('job:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0">
            Visit For
            <!-- <pre>{{customer}}</pre> --></v-flex
          >
          <v-flex
            class="m-0 form-title-create-link pl-2"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
            v-on:click="refCustomerDialog = true"
          >
            <template v-if="lodash.isEmpty(customer)">Client Name</template>
            <template v-else>
              <div class="cursor-pointer">
                <span v-on:click="checkCustomer(customer.id)">
                  {{ customer.display_name }}
                </span>
              </div>
            </template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack()"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate()"
      >
        Save Visit
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="jobForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate()"
      >
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: calc(100vh - 185px); position: relative"
        > -->
        <div class="p-5 pt-0">
          <v-row>
            <v-col cols="12" v-if="getPermission('job:create')">
              <v-container fluid>
                <v-card flat class="remove-border-radius">
                  <v-card-text ref="overview" class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="7" class="pb-0">
                        <div>
                          <label class="font-weight-600 font-size-18 required"
                            >Visit Title</label
                          >
                          <v-text-field
                            v-model.trim="jobCreate.job_title"
                            dense
                            filled
                            label="Title"
                            solo
                            flat
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            color="cyan"
                            :rules="[
                              validateRules.required(
                                jobCreate.job_title,
                                'Visit Title'
                              ),
                              validateRules.minLength(
                                jobCreate.job_title,
                                'Visit Title',
                                1
                              ),
                              validateRules.maxLength(
                                jobCreate.job_title,
                                'Visit Title',
                                100
                              ),
                            ]"
                          ></v-text-field>
                          <v-textarea
                            v-model.trim="jobCreate.description"
                            auto-grow
                            dense
                            filled
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            color="cyan"
                            label="Description"
                            solo
                            flat
                            :rules="[
                              validateRules.minLength(
                                jobCreate.description,
                                'Description',
                                1
                              ),
                              validateRules.maxLength(
                                jobCreate.description,
                                'Description',
                                1024
                              ),
                            ]"
                            row-height="25"
                          ></v-textarea>
                        </div>

                        <v-layout class="my-4">
                          <v-flex md6 class="mr-2 custom-border-right">
                            <table width="100%">
                              <tr>
                                <td class="font-weight-600">
                                  <label>Service Location</label>
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="customerPropertyDialog = true"
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                  <v-icon
                                    class="ml-3"
                                    v-on:click="routeToServiceHistory()"
                                    color="cyan"
                                    small
                                    >mdi-history</v-icon
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  <label>{{ property.property_address }}</label>
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-600">
                                  <label>Contact details</label>
                                  <v-chip
                                    v-if="property_contact_person.tenant"
                                    small
                                    color="green white--text"
                                    label
                                    class="ml-2 tenant-small-chip"
                                    >Tenant</v-chip
                                  >
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="
                                      person_type = 'property';
                                      customerPersonDialog = true;
                                    "
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr v-if="property_contact_person.display_name">
                                <td class="py-0">
                                  <label>{{
                                    property_contact_person.display_name
                                  }}</label>
                                </td>
                              </tr>
                              <tr v-if="property_contact_person.primary_phone">
                                <td class="py-0">
                                  <label>{{
                                    property_contact_person.primary_phone
                                  }}</label>
                                </td>
                              </tr>
                              <tr v-if="property_contact_person.primary_email">
                                <td class="py-0">
                                  <label>{{
                                    property_contact_person.primary_email
                                  }}</label>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <v-layout>
                                    <v-flex
                                      ><label class="font-weight-600">
                                        Email Notification
                                      </label></v-flex
                                    >
                                    <v-flex>
                                      <v-tooltip
                                        top
                                        content-class="custom-top-tooltip"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-switch
                                            class="m-0 p-0"
                                            color="cyan"
                                            dense
                                            v-model="
                                              jobCreate.property_person_notify
                                            "
                                            inset
                                          ></v-switch>
                                        </template>
                                        <span
                                          v-if="
                                            jobCreate.property_person_notify ==
                                            1
                                          "
                                          >Send Notification</span
                                        >
                                        <span v-else>No Notification</span>
                                      </v-tooltip>
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                            </table>
                          </v-flex>
                          <v-flex md6 class="ml-2">
                            <table width="100%">
                              <tr>
                                <td class="font-weight-600">
                                  <label>Billing Address</label>
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="customerBillingDialog = true"
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  <label>{{ billing.property_address }}</label>
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-600">
                                  <label>Contact details</label>
                                  <v-chip
                                    v-if="billing_contact_person.tenant"
                                    small
                                    color="green white--text"
                                    label
                                    class="ml-2 tenant-small-chip"
                                    >Tenant</v-chip
                                  >
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="
                                      person_type = 'billing';
                                      customerPersonDialog = true;
                                    "
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr v-if="billing_contact_person.display_name">
                                <td class="py-0">
                                  <label>{{
                                    billing_contact_person.display_name
                                  }}</label>
                                </td>
                              </tr>
                              <tr v-if="billing_contact_person.primary_phone">
                                <td class="py-0">
                                  <label>{{
                                    billing_contact_person.primary_phone
                                  }}</label>
                                </td>
                              </tr>
                              <tr v-if="billing_contact_person.primary_email">
                                <td class="py-0">
                                  <label>{{
                                    billing_contact_person.primary_email
                                  }}</label>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <v-layout>
                                    <v-flex
                                      ><label class="font-weight-600">
                                        Email Notification
                                      </label></v-flex
                                    >
                                    <v-flex>
                                      <v-tooltip
                                        top
                                        content-class="custom-top-tooltip"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-switch
                                            class="m-0 p-0"
                                            color="cyan"
                                            dense
                                            v-model="
                                              jobCreate.billing_person_notify
                                            "
                                            inset
                                          ></v-switch>
                                        </template>
                                        <span
                                          v-if="
                                            jobCreate.billing_person_notify == 1
                                          "
                                          >Send Notification</span
                                        >
                                        <span v-else>No Notification</span>
                                      </v-tooltip>
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                            </table>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <v-col cols="5" class="pb-0">
                        <table width="100%">
                          <tr v-if="jobCreate.schedule_tab == 'recurring'">
                            <td width="150" class="font-weight-600">
                              <label>Job number</label>
                            </td>
                            <td class="font-weight-700">
                              <label>{{ jobCreate.barcode }}</label>
                            </td>
                          </tr>
                          <tr>
                            <td width="150" class="font-weight-600">
                              <label>Visit number</label>
                            </td>
                            <td class="font-weight-700">
                              <label>{{ jobCreate.visit_barcode }}</label>
                            </td>
                          </tr>
                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="reference-no">Reference #</label>
                            </td>
                            <td>
                              <v-text-field
                                id="reference-no"
                                v-model.trim="jobCreate.reference"
                                dense
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                color="cyan"
                                label="Reference #"
                                :rules="[
                                  validateRules.minLength(
                                    jobCreate.reference,
                                    'Reference',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    jobCreate.reference,
                                    'Reference',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                              ></v-text-field>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td width="150" class="font-weight-600">
                              <label for="po-number">PO Number</label>
                            </td>
                            <td>
                              <v-text-field
                                id="po-number"
                                v-model.trim="jobCreate.po_number"
                                dense
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                color="cyan"
                                label="PO Number"
                                :rules="[
                                  validateRules.minLength(
                                    jobCreate.po_number,
                                    'PO Number',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    jobCreate.po_number,
                                    'PO Number',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                              ></v-text-field>
                            </td>
                          </tr> -->
                          <!-- <tr>
                            <td width="150" class="font-weight-600">
                              <label for="payment-mode">Payment Mode</label>
                            </td>
                            <td>
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="payment-mode"
                                :items="paymentModeList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="jobCreate.payment_mode"
                                label="Payment Mode"
                                solo
                                flat
                                item-color="cyan"
                                item-text="value"
                                item-value="id"
                                hide-details
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Payment Mode Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr> -->
                          <!-- <tr>
                              <td width="150" class="font-weight-600">
                                <label for="quotation">Quotation</label>
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="quotation"
                                  :items="quotationList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="quotationId"
                                  label="Quotation"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="value"
                                  item-value="id"
                                  hide-details
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No Quotation Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr> -->
                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="job-type" class="required"
                                >Job Type</label
                              >
                            </td>
                            <td>
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="job-type"
                                :items="jobTypeList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="jobCreate.job_type"
                                label="Job Type"
                                solo
                                flat
                                item-color="cyan"
                                item-text="value"
                                item-value="id"
                                hide-details
                                multiple
                                :rules="[
                                  validateRules.required(
                                    jobCreate.job_type,
                                    'Job Type'
                                  ),
                                ]"
                                return-object
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Job Type Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="priority">Priority</label>
                            </td>
                            <td>
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="priority"
                                :items="jobPriorityList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="jobCreate.priority"
                                label="Priority"
                                solo
                                flat
                                item-color="cyan"
                                item-text="value"
                                item-value="id"
                                hide-details
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Priority Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>

                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="tags">Tag</label>
                            </td>
                            <td>
                              <v-text-field
                                id="tags"
                                v-model.trim="response_schedule.tags"
                                dense
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                color="cyan"
                                label="Tag"
                                :rules="[
                                  validateRules.minLength(
                                    response_schedule.tags,
                                    'Tag',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    response_schedule.tags,
                                    'Tag',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="contract">Contract </label>
                            </td>
                            <td>
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="contract"
                                :items="customerContractList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="jobCreate.contract"
                                label="Contract"
                                solo
                                flat
                                :return-object="true"
                                item-color="cyan"
                                item-text="barcode"
                                item-value="id"
                                hide-details
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Contract Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <template v-slot:item="data">
                                  <table>
                                    <tr>
                                      <td>
                                        <div>
                                          <Barcode
                                            :barcode="data.item.barcode"
                                          ></Barcode>
                                        </div>
                                        <div>{{ data.item.job_title }}</div>
                                        <div>
                                          Services:
                                          {{ data.item.total_service }}/{{
                                            data.item.visit_count
                                          }}/{{ data.item.balaced }}
                                        </div>
                                      </td>
                                    </tr>
                                    <!-- <tr>
                                      <td>Used: {{ data.item.visit_count }}</td>
                                    </tr>
                                    <tr>
                                      <td>Balaced: {{ data.item.balaced }}</td>
                                    </tr> -->
                                  </table>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12" class="job-schedule" v-if="!visitId">
              <v-container fluid>
                <v-tabs
                  v-model="jobCreate.schedule_tab"
                  background-color="transparent"
                  centered
                  :disabled="pageLoading"
                  color="cyan"
                  icons-and-text
                  class="mb-3"
                >
                  <v-tab href="#one-off">
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray">mdi-calendar</v-icon>
                      </div>
                      <div class="text-left">
                        <h3 class="text-capitalize">One-Off Job</h3>
                        <p class="m-0 text-capitalize">
                          A one time job with one or more visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                  <v-tab href="#recurring">
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray"
                          >mdi-calendar-multiple</v-icon
                        >
                      </div>
                      <div class="text-left">
                        <h3 class="text-capitalize">Recurring Job</h3>
                        <p class="m-0 text-capitalize">
                          A contract job with repeating visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="jobCreate.schedule_tab"
                  class="remvoe-overflow"
                >
                  <v-tab-item value="one-off">
                    <v-layout>
                      <v-flex :md4="show_calendar" :md12="!show_calendar">
                        <OneOffSchedule
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></OneOffSchedule>
                      </v-flex>
                      <v-flex v-if="show_calendar" md8>
                        <Calendar
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></Calendar>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item value="recurring">
                    <v-layout>
                      <v-flex :md4="show_calendar" :md12="!show_calendar">
                        <RecurringSchedule
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></RecurringSchedule>
                      </v-flex>
                      <v-flex v-if="show_calendar" md8>
                        <Calendar
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></Calendar>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                </v-tabs-items>
              </v-container>
            </v-col>
            <v-col cols="12" v-if="getPermission('line-item:create')">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text ref="lineItem" class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <JobLineItemNew
                          is-job
                          can-update
                          :property-id="jobProperty"
                          :db-line-items="dbLineItems"
                          :db-equipments="dbEquipments"
                          :discount-value="jobCreate.discount_value"
                          :discount-value-type="jobCreate.discount_value_type"
                          :apply-tax="jobCreate.tax_applied"
                          :adjustment-value="jobCreate.adjustment"
                          v-on:update:equipment="updateEquipment($event)"
                          v-on:update:line-item="updateLineItem($event)"
                          v-on:update:line-item-calculation="
                            updateLineItemCalculation($event)
                          "
                        ></JobLineItemNew>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <JobTermsConditions
                :update-data="updateData"
                :page-loading="pageLoading"
              ></JobTermsConditions>
            </v-col>
            <v-col cols="12">
              <JobNotesAttachment
                :update-data="updateData"
                :page-loading="pageLoading"
              ></JobNotesAttachment>
            </v-col>
          </v-row>
        </div>
        <!-- </perfect-scrollbar> -->
      </v-form>

      <template v-if="refEntityId && refCustomerDialog">
        <RefCustomerDialog
          :entity="refEntityId"
          :customer-dialog="refCustomerDialog"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomer="refSelectCustomer"
        ></RefCustomerDialog>
      </template>
      <template v-if="refCustomerPropertyDialog">
        <RefCustomerPropertyDialog
          disabled-auto-select
          :customer-property-dialog="refCustomerPropertyDialog"
          :customer="refCustomerId"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomerProperty="refSelectCustomerProperty"
        ></RefCustomerPropertyDialog>
      </template>

      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          disabled-auto-select
          :customerPersonDialog="customerPersonDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          disabled-auto-select
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
      <template v-if="customerBillingDialog">
        <CustomerBillingDialog
          disabled-auto-select
          :customerBillingDialog="customerBillingDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerBillingDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { QUERY, POST, PUT } from "@/core/services/store/request.module";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerBillingDialog from "@/view/pages/partials/Select-Customer-Billing.vue";
import JobLineItemNew from "@/view/pages/partials/Line-Item-New.vue";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import JobTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import JobNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import OneOffSchedule from "@/view/pages/job/partials/One-Off-Schedule.vue";
import { JobEventBus } from "@/core/lib/job/job.lib";
import Calendar from "@/view/pages/partials/Calendar.vue";
import RecurringSchedule from "@/view/pages/job/partials/Recurring-Schedule.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import RefCustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import ObjectPath from "object-path";
import RefCustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import {
  toSafeInteger,
  map,
  concat,
  compact,
  isEmpty,
  filter,
  isArray,
} from "lodash";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "job-create",
  title: "Create Job",
  data() {
    return {
      refEntityId: 1,
      refCustomerDialog: false,
      refCustomerPropertyDialog: false,
      formLoading: false,
      pageLoading: false,
      preContract: 0,
      jobCreated: false,
      lineItemCreated: false,
      scheduleCreated: false,
      engineerAssigned: false,
      show_calendar: false,
      customer: {},
      property: {},
      billing: {},
      barcodeSetting: {},
      jobCreate: {
        job_title: null,
        description: null,
        po_number: null,
        payment_mode: 0,
        property_person_notify: 1,
        billing_person_notify: 1,
        barcode: null,
        reference: null,
        job_type: null,
        priority: 3,
        schedule_tab: "one-off",
        discount_value: null,
        discount_value_type: 1,
        tax_applied: null,
        adjustment: null,

        schedule_later: null,
        total_occurence: 1,
      },
      jobPriorityList: [
        {
          id: 1,
          value: "High",
        },
        {
          id: 2,
          value: "Medium",
        },
        {
          id: 3,
          value: "Low",
        },
      ],
      jobScheduleTypeList: [
        {
          id: "one-off",
          value: "One-Off Job",
        },
        {
          id: "recurring",
          value: "Recurring Job",
        },
      ],
      customerPersonDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      person_type: null,
      quotationId: null,
      invoiceId: null,
      jobId: null,
      visitId: null,
      property_contact_person: {},
      billing_contact_person: {},
      jobTypeList: [],
      customerContractList: [],
      paymentModeList: [],
      quotationList: [],
      lineItem: [],
      equipments: [],
      dbLineItems: [],
      dbEquipments: [],
      note_attachments: {},
      terms_condition: null,
      lineItemCalculation: {
        taxApplied: 0,
        discountValue: 0,
        adjustmentAmount: 0,
        discountType: 1,
        discountValueType: 0,
      },
      updateData: {
        term_conditions: null,
        admin_remark: null,
        client_remark: null,
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0,
        documents: [],
      },
      recurring_pattern: {},
      recurring_dates: [],
      recurring_schedule: {},
      one_off_schedule: {},
      assigned_engineers: {},
      response_schedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        tags: null,
      },
    };
  },
  components: {
    Calendar,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    CustomerBillingDialog,
    JobLineItemNew,
    CreateUpdateTemplate,
    JobTermsConditions,
    OneOffSchedule,
    RecurringSchedule,
    JobNotesAttachment,
    Barcode,
    RefCustomerPropertyDialog,
    RefCustomerDialog,
  },
  methods: {
    refCloseDialog() {
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
    },
    refResetAll() {
      this.refEntityId = 1;
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
      this.refCustomerId = 0;
    },

    refSelectCustomer(param) {
      this.refCustomerId = param;
      this.$nextTick(() => {
        this.refCustomerDialog = false;
        this.refCustomerPropertyDialog = true;
      });
    },

    checkCustomer(data) {
      this.CustomerId = data;

      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: {
            id: this.CustomerId ?? undefined,
          },
        })
      );
    },

    updateSkills() {
      const { job_type } = this.jobCreate;
      JobScheduleTeamEventBus.$emit(
        "update:skills",
        job_type.map((x) => x.value)
      );
    },
    isConversion() {
      return !!+this.quotationId;
    },
    updateLineItem(rows) {
      this.lineItem = rows;
    },
    updateEquipment(rows) {
      this.equipments = rows;
    },
    updateLineItemCalculation(row) {
      this.lineItemCalculation.taxApplied = row.apply_tax;
      this.lineItemCalculation.discountValue = row.discount_value;
      this.lineItemCalculation.adjustmentAmount = row.adjustment;
      this.lineItemCalculation.discountType = 1;
      this.lineItemCalculation.discountValueType = row.discount_value_type;
    },
    routeToServiceHistory() {
      this.forcePush = true;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("property.detail", {
            params: { id: this.property.id },
            query: {
              tab: "property-history",
            },
          })
        );
      });
    },

    refSelectCustomerProperty(param) {
      this.refPropertyId = param;
      let route = this.getDefaultRoute("job.create", {
        query: {
          customer: this.refCustomerId,
          property: this.refPropertyId,
          duplicate: ObjectPath.get(this.$route, "query.duplicate"),
          isDupChanged: 1,
        },
      });
      const resolved = this.$router.resolve(route);
      window.location.href = resolved.href;
    },
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.jobPropertyPerson == param) {
          this.closeDialog();
          return false;
        }
        this.jobPropertyPerson = param;
      }
      if (this.person_type == "billing") {
        if (this.jobBillingPerson == param) {
          this.closeDialog();
          return false;
        }
        this.jobBillingPerson = param;
      }
      this.closeDialog();
      this.pushToRouteJob();
    },
    selectCustomerProperty(param) {
      if (this.jobProperty == param) {
        this.closeDialog();
        return false;
      }
      this.jobProperty = param;
      this.closeDialog();
      this.pushToRouteJob();
    },
    selectCustomerBilling(param) {
      if (this.jobBilling == param) {
        this.closeDialog();
        return false;
      }
      this.jobBilling = param;
      this.closeDialog();
      this.pushToRouteJob();
    },
    pushToRouteJob() {
      this.$router
        .replace(
          this.getDefaultRoute("job.create", {
            query: {
              customer: this.jobCustomer,
              billing: this.jobBilling,
              property: this.jobProperty,
              property_person: this.jobPropertyPerson,
              billing_person: this.jobBillingPerson,
              quotation: this.quotationId,
              invoice: this.invoiceId,
              ticket: this.ticketId,
            },
          })
        )
        .then(() => {
          this.getOptions();
        });
    },
    async updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.jobForm.validate();

      const formErrors = _this.validateForm(_this.$refs.jobForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (_this.jobCreate.schedule_tab == "one-off") {
        _this.jobCreate.total_occurence = 1;
      }

      if (_this.jobCreate.contract && _this.jobCreate.contract.id > 0) {
        if (_this.jobId > 0) {
          _this.jobCreate.total_occurence = 1;
          if (
            _this.jobCreate.contract.balaced <= 0 &&
            _this.jobCreate.contract.id != _this.preContract
          ) {
            ErrorEventBus.$emit(
              "update:error",
              InitializeError("Contract Total Services Exceeded")
            );
            return false;
          }
        } else {
          if (
            _this.jobCreate.contract.balaced < _this.jobCreate.total_occurence
          ) {
            ErrorEventBus.$emit(
              "update:error",
              InitializeError("Contract Total Services Exceeded")
            );
            return false;
          }
        }
      }

      const line_items = concat(_this.equipments, _this.lineItem);

      let validateLineItem = compact(
        map(line_items, function (row) {
          return row.product_id;
        })
      );

      if (isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select service then try again.")
        );
        return false;
      }

      /* if (isArray(_this.assigned_engineers) && _this.assigned_engineers.length < 1) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select team and then try again.")
        );
        return false;
      } */

      if (!validateStatus) {
        return false;
      }

      const formData = new Object({
        reference: _this.jobCreate.reference,
        customer: _this.jobCustomer,
        billing: _this.jobBilling,
        property: _this.jobProperty,
        property_contact_person: _this.jobPropertyPerson,
        billing_contact_person: _this.jobBillingPerson,
        quotation: _this.quotationId,
        invoice: _this.invoiceId,
        title: _this.jobCreate.job_title,
        attention: _this.jobCreate.attention,
        po_number: _this.jobCreate.po_number,
        payment_mode: _this.jobCreate.payment_mode,
        description: _this.jobCreate.description,
        documents: _this.note_attachments.documents,
        admin_remark: _this.note_attachments.admin_notes,
        client_remark: _this.note_attachments.client_notes,
        term_conditions: _this.terms_condition,
        tags: _this.response_schedule.tags,

        tax_applied: _this.lineItemCalculation.taxApplied,
        discount_value: _this.lineItemCalculation.discountValue,
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
        job_type: _this.jobCreate.job_type,
        property_person_notify: toSafeInteger(
          _this.jobCreate.property_person_notify
        ),
        billing_person_notify: toSafeInteger(
          _this.jobCreate.property_person_notify
        ),
        schedule_later: 0,
        priority: _this.jobCreate.priority,
        contract:
          _this.jobCreate && _this.jobCreate.contract
            ? _this.jobCreate.contract.id
            : null,
        visit: _this.visitId,
        type: _this.jobCreate.schedule_tab == "one-off" ? 1 : 2,
        total_occurence: _this.jobCreate.total_occurence,
      });

      _this.formLoading = true;

      if (_this.jobId > 0 && this.duplicatejob == 0) {
        _this.$store.dispatch(PUT, {
          url: "job/" + _this.jobId,
          data: formData,
        });
      }

      if (_this.visitId == 0) {
        try {
          const job = await _this.$store.dispatch(POST, {
            url: "job",
            data: formData,
          });

          _this.jobId = toSafeInteger(job.data.id);
          _this.jobCreated = true;
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Job is not created. Please try again.")
          );
          return false;
        }
      }

      if (_this.jobCreated || _this.jobId) {
        if (!_this.lineItemCreated) {
          try {
            await _this.CreateLineItems({
              type: "ticket",
              parent: _this.jobId,
              formData: line_items,
            });
            _this.lineItemCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.formLoading = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError("Line items are not created. Please try again.")
            );
            return false;
          }
        }

        if (formData.type == 2) {
          if (!_this.scheduleCreated && _this.recurring_dates.length) {
            try {
              await _this.createRecurringSchedule({
                job: _this.jobId,
                dates: _this.recurring_dates,
                pattern: _this.recurring_pattern,
                schedule: _this.recurring_schedule,
              });
              _this.scheduleCreated = true;
            } catch (error) {
              _this.logError(error);
              _this.formLoading = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Recurring Schedule is not created. Please try again."
                )
              );
              return false;
            }
          }

          if (_this.scheduleCreated && !_this.engineerAssigned) {
            try {
              let assigned_engineers = _this.assigned_engineers;
              let teamId = _this.teamId;
              /* if (_this.isEngineerChannel()) {
                let current_user = _this.getCurrentUser();
                let current_user_id = toSafeInteger(current_user.engineer);
                if (current_user_id) {
                  assigned_engineers = new Object({
                    assigned_team: [current_user_id],
                  });
                }
              }
 */
              /* if (
                !isEmpty(assigned_engineers) &&
                assigned_engineers.assigned_team &&
                assigned_engineers.assigned_team.length
              ) */
              if (
                isArray(assigned_engineers) &&
                assigned_engineers.length > 0
              ) {
                await _this.assignRecurringTeam({
                  job: _this.jobId,
                  team: assigned_engineers,
                  teamId: teamId,
                });
                _this.engineerAssigned = true;
              }
            } catch (error) {
              _this.logError(error);
              _this.formLoading = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Engineer are not allocated to Schedule. Please try again."
                )
              );
              return false;
            }
          }
        } else {
          if (!_this.scheduleCreated && !_this.visitId) {
            try {
              const visit = await _this.createJobSchedule({
                job: _this.jobId,
                schedule: _this.one_off_schedule,
                team: _this.assigned_engineers,
              });
              _this.visitId = toSafeInteger(visit.id);
              _this.scheduleCreated = true;
            } catch (error) {
              _this.logError(error);
              _this.formLoading = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError("Schedule is not created. Please try again.")
              );
              return false;
            }
          }

          if (
            _this.visitId &&
            _this.scheduleCreated &&
            !_this.engineerAssigned
          ) {
            try {
              let assigned_engineers = _this.assigned_engineers;
              let teamId = _this.teamId;
              // console.log(assigned_engineers, "assigned_engineers11111");
              /* if (_this.isEngineerChannel()) {
                let current_user = _this.getCurrentUser();
                let current_user_id = toSafeInteger(current_user.engineer);
                if (current_user_id) {
                  assigned_engineers = [current_user_id];
                }
              } */

              if (
                isArray(assigned_engineers) &&
                assigned_engineers.length > 0
              ) {
                await _this.assignTeam({
                  job: _this.jobId,
                  visit: _this.visitId,
                  team: assigned_engineers,
                  teamId: teamId,
                });
                _this.engineerAssigned = true;
              }
            } catch (error) {
              _this.logError(error);
              _this.formLoading = false;
              ErrorEventBus.$emit(
                "update:error",
                InitializeError(
                  "Engineer are not allocated to Schedule. Please try again."
                )
              );
              return false;
            }
          }
        }
      }

      _this.formLoading = false;

      _this.$nextTick(() => {
        this.forcePush = true;
        if (formData.type == 1) {
          _this.$router.push(
            _this.getDefaultRoute("visit.detail", {
              params: { id: _this.visitId },
            })
          );
        } else {
          _this.$router.push(_this.getDefaultRoute("visit", {}));
        }
      });
    },
    getLineItems(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(QUERY, {
            url: "line-item/new",
            data,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setQuotationData(param) {
      const _this = this;

      _this.jobCreate.job_title = param.job_title;
      _this.jobCreate.description = param.description;
      _this.jobCreate.property_person_notify = param.property_person_notify;
      _this.jobCreate.billing_person_notify = param.billing_person_notify;
      //  _this.jobCreate.reference = `Quotation # ${param.barcode}`;
      _this.jobCreate.discount_value = param.discount_value;
      _this.jobCreate.discount_value_type = param.discount_value_type;
      _this.jobCreate.tax_applied = param.tax_applied;

      _this.jobCreate.adjustment = param.adjustment;

      //  _this.updateData.term_conditions = param.term_conditions;
      // _this.updateData.admin_remark = param.admin_remark;
      //  _this.updateData.client_remark = param.client_remark;
      //  _this.updateData.notify_admin = param.notify_admin;
      //  _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          quotation: _this.quotationId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              project_price: data[i].project_price,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return row.product_type == "service";
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setInvoiceData(param) {
      const _this = this;

      _this.jobCreate.job_title = param.title;
      _this.jobCreate.description = param.description;
      _this.jobCreate.property_person_notify = param.property_person_notify;
      _this.jobCreate.billing_person_notify = param.billing_person_notify;
      _this.jobCreate.reference = `Invoice # ${param.barcode}`;
      _this.jobCreate.discount_value = param.discount_value;
      _this.jobCreate.discount_value_type = param.discount_value_type;
      _this.jobCreate.tax_applied = param.tax_applied;

      _this.jobCreate.adjustment = param.adjustment;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          invoice: _this.invoiceId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              project_price: data[i].project_price,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getOptions() {
      this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/options",
          data: {
            quotation: this.quotationId,
            invoice: this.invoiceId,
            job: this.jobId,
            visit: this.visitId,
            customer: this.jobCustomer,
            duplicate: this.duplicatejob,
            billing: this.jobBilling,
            property: this.jobProperty,
            property_person: this.jobPropertyPerson,
            billing_person: this.jobBillingPerson,
          },
        })
        .then(({ data }) => {
          if (data.barcode) {
            this.jobCreate.barcode = data.barcode;
          }

          if (data.visit_barcode) {
            this.jobCreate.visit_barcode = data.visit_barcode;
          }

          if (data.ticket_types) {
            this.jobTypeList = data.ticket_types;
          }

          if (data.contract_list) {
            this.customerContractList = data.contract_list;
          }

          if (data.payment_modes) {
            this.paymentModeList = data.payment_modes;
          }

          if (data.quotations) {
            this.quotationList = data.quotations;
          }
          if (!isEmpty(data.client_remark)) {
            this.updateData.client_remark = data.client_remark[0].value;
          }
          if (!isEmpty(data.options)) {
            this.updateData.term_conditions = data.options.terms_conditions;
          }

          if (this.visitId > 0) {
            if (data.visit.barcode) {
              this.jobCreate.visit_barcode = data.visit.barcode;
            }

            if (
              data &&
              data.visit &&
              data.visit.ticket &&
              data.visit.ticket.title
            ) {
              this.jobCreate.job_title = data.visit.ticket.title;
            }
            if (data.visit.ticket.description) {
              this.jobCreate.description = data.visit.ticket.description;
            }
            if (data.visit.tags) {
              this.response_schedule.tags = data.visit.tags;
            }
            if (data.visit.ticket.reference) {
              this.jobCreate.reference = data.visit.ticket.reference;
            }
            if (data.visit.ticket.po_number) {
              this.jobCreate.po_number = data.visit.ticket.po_number;
            }
            if (data.visit.ticket.payment_mode) {
              this.jobCreate.payment_mode = data.visit.ticket.payment_mode.id;
            }
            if (data.visit.ticket.priority_text) {
              // console.log(data.visit.ticket.priority);
              this.jobCreate.priority = data.visit.ticket.priority;
            }

            if (data.visit.contract) {
              contractData = {
                barcode: data?.visit?.contract?.barcode ?? null,
                id: data?.visit?.contract?.id ?? 0,
                job_title: data?.visit?.contract?.job_title ?? null,
                status:  data?.visit?.contract?.status ?? null,
                total_service: data?.visit?.contract?.total_service ?? 0,
                visit_count: data?.visit?.contract?.visit?.length,
              }
              let contractData = data?.visit?.contract;
              this.jobCreate.contract = contractData;
              this.preContract = contractData?.id;
            }

            if (data.visit.ticket.ticket_type) {
              const job_ticket_type = [];
              for (
                let index = 0;
                index < data.visit.ticket.ticket_type.length;
                index++
              ) {
                const findOut = this.lodash.find(this.jobTypeList, {
                  value: data.visit.ticket.ticket_type[index],
                });
                if (findOut) {
                  job_ticket_type.push(findOut);
                }
              }
              this.jobCreate.job_type = job_ticket_type;
            }
            if (data.visit.ticket.id) {
              this.jobId = data.visit.ticket.id;
            }

            if (data.visit.ticket.term_conditions) {
              this.updateData.term_conditions =
                data.visit.ticket.term_conditions;
            }
            if (data.visit.ticket.client_remark) {
              this.updateData.client_remark = data.visit.ticket.client_remark;
            }

            if (data.visit.ticket.customer) {
              //  console.log(data.visit.ticket.customer);
              this.customer = data.visit.ticket.customer;
              this.jobCustomer = this.customer.id;
            }

            if (data.visit.ticket.billing) {
              this.billing = data.visit.ticket.billing;
              this.jobBilling = this.billing.id;
            }

            if (data.visit.ticket.property) {
              this.property = data.visit.ticket.property;
              this.jobProperty = this.property.id;
            }

            if (data.visit.ticket.property_person) {
              this.property_contact_person = data.visit.ticket.property_person;
              this.jobCreate.attention =
                this.property_contact_person.display_name;
              this.jobPropertyPerson = this.property_contact_person.id;
            }

            if (data.visit.ticket.billing_person) {
              this.billing_contact_person = data.visit.ticket.billing_person;
              this.jobBillingPerson = this.billing_contact_person.id;
            }

            if (data && data.visit && data.visit.ticket && data.visit.ticket) {
              this.jobCreate.billing_person_notify = toSafeInteger(
                data.visit.ticket.billing_person_notify
              );
            }
            if (data && data.visit && data.visit.ticket && data.visit.ticket) {
              this.jobCreate.property_person_notify = toSafeInteger(
                data.visit.ticket.property_person_notify
              );
            }

            if (
              data &&
              data.visit &&
              data.visit.ticket &&
              data.visit.ticket.discount_value
            ) {
              this.jobCreate.discount_value = data.visit.ticket.discount_value;
            }
            if (
              data &&
              data.visit &&
              data.visit.ticket &&
              data.visit.ticket.discount_value_type
            ) {
              this.jobCreate.discount_value_type =
                data.visit.ticket.discount_value_type;
            }

            if (
              data &&
              data.visit &&
              data.visit.ticket &&
              data.visit.ticket.tax_applied
            ) {
              this.jobCreate.tax_applied = data.visit.ticket.tax_applied;
            }
            if (
              data &&
              data.visit &&
              data.visit.ticket &&
              data.visit.ticket.adjustment
            ) {
              this.jobCreate.adjustment = data.visit.ticket.adjustment;
            }

            this.getLineItems({
              job: data.visit.ticket.id,
            }).then((data) => {
              const result = [];
              for (let i = 0; i < data.length; i++) {
                result.push({
                  id: data[i].id,
                  group: data[i].group,
                  group_primary: data[i].group_primary,
                  product: data[i].product,
                  product_id: data[i].product_id,
                  serial_no: data[i].serial_no,
                  eq_model: data[i].eq_model,
                  location: data[i].location,
                  product_type: data[i].product_type,
                  project_price: data[i].project_price,
                  has_warranty: data[i].has_warranty,
                  warranty: data[i].warranty,
                  description: data[i].description,
                  rate: data[i].rate,
                  threshold_price: data[i].threshold_price,
                  quantity: data[i].quantity,
                  uom: data[i].uom,
                  total: data[i].total,
                  visit_barcode: data[i].visit_barcode,
                  order: data[i].order,
                  is_optional: data[i].is_optional,
                });
              }

              this.$nextTick(() => {
                this.dbLineItems = filter(result, function (row) {
                  return (
                    row.product_type == "goods" || row.product_type == "service"
                  );
                });
                this.dbEquipments = filter(result, {
                  product_type: "equipment",
                });
              });
            });
          }

          if (this.duplicatejob > 0) {
            if (data.duplicate.barcode) {
              this.jobCreate.visit_barcode = data.visit_barcode;
            }

            if (
              data &&
              data.duplicate &&
              data.duplicate.ticket &&
              data.duplicate.ticket.title
            ) {
              this.jobCreate.job_title = data.duplicate.ticket.title;
            }
            if (data.duplicate.ticket.description) {
              this.jobCreate.description = data.duplicate.ticket.description;
            }
            if (data.duplicate.tags) {
              this.response_schedule.tags = data.duplicate.tags;
            }

            if (data.duplicate.ticket.reference) {
              this.jobCreate.reference = data.duplicate.ticket.reference;
            }
            if (data.duplicate.ticket.po_number) {
              this.jobCreate.po_number = data.duplicate.ticket.po_number;
            }
            if (data.duplicate.ticket.payment_mode) {
              this.jobCreate.payment_mode =
                data.duplicate.ticket.payment_mode.id;
            }
            if (data.duplicate.ticket.priority_text) {
              // console.log(data.duplicate.ticket.priority);
              this.jobCreate.priority = data.duplicate.ticket.priority;
            }

            if (data.duplicate.ticket.ticket_type) {
              const job_ticket_type = [];
              for (
                let index = 0;
                index < data.duplicate.ticket.ticket_type.length;
                index++
              ) {
                const findOut = this.lodash.find(this.jobTypeList, {
                  value: data.duplicate.ticket.ticket_type[index],
                });
                if (findOut) {
                  job_ticket_type.push(findOut);
                }
              }
              this.jobCreate.job_type = job_ticket_type;
            }
            if (data.duplicate.ticket.id) {
              this.jobId = data.duplicate.ticket.id;
            }

            if (data.duplicate.ticket.term_conditions) {
              this.updateData.term_conditions =
                data.duplicate.ticket.term_conditions;
            }
            if (data.duplicate.ticket.client_remark) {
              this.updateData.client_remark =
                data.duplicate.ticket.client_remark;
            }

            if (data.duplicate.ticket.customer) {
              //  console.log(data.duplicate.ticket.customer);
              this.customer = data.duplicate.ticket.customer;
              this.jobCustomer = this.customer.id;
            }

            if (data.duplicate.ticket.billing) {
              this.billing = data.duplicate.ticket.billing;
              this.jobBilling = this.billing.id;
            }

            if (data.duplicate.ticket.property) {
              this.property = data.duplicate.ticket.property;
              this.jobProperty = this.property.id;
            }

            if (data.duplicate.ticket.property_person) {
              this.property_contact_person =
                data.duplicate.ticket.property_person;
              this.jobCreate.attention =
                this.property_contact_person.display_name;
              this.jobPropertyPerson = this.property_contact_person.id;
            }

            if (data.duplicate.ticket.billing_person) {
              this.billing_contact_person =
                data.duplicate.ticket.billing_person;
              this.jobBillingPerson = this.billing_contact_person.id;
            }

            if (
              data &&
              data.duplicate &&
              data.duplicate.ticket &&
              data.duplicate.ticket
            ) {
              this.jobCreate.billing_person_notify = toSafeInteger(
                data.duplicate.ticket.billing_person_notify
              );
            }
            if (
              data &&
              data.duplicate &&
              data.duplicate.ticket &&
              data.duplicate.ticket
            ) {
              this.jobCreate.property_person_notify = toSafeInteger(
                data.duplicate.ticket.property_person_notify
              );
            }

            if (
              data &&
              data.duplicate &&
              data.duplicate.ticket &&
              data.duplicate.ticket.discount_value
            ) {
              this.jobCreate.discount_value =
                data.duplicate.ticket.discount_value;
            }
            if (
              data &&
              data.duplicate &&
              data.duplicate.ticket &&
              data.duplicate.ticket.discount_value_type
            ) {
              this.jobCreate.discount_value_type =
                data.duplicate.ticket.discount_value_type;
            }

            if (
              data &&
              data.duplicate &&
              data.duplicate.ticket &&
              data.duplicate.ticket.tax_applied
            ) {
              this.jobCreate.tax_applied = data.duplicate.ticket.tax_applied;
            }
            if (
              data &&
              data.duplicate &&
              data.duplicate.ticket &&
              data.duplicate.ticket.adjustment
            ) {
              this.jobCreate.adjustment = data.duplicate.ticket.adjustment;
            }

            this.getLineItems({
              job: data.duplicate.ticket.id,
            }).then((data) => {
              const result = [];
              for (let i = 0; i < data.length; i++) {
                result.push({
                  id: null,
                  group: data[i].group,
                  group_primary: data[i].group_primary,
                  product: data[i].product,
                  product_id: data[i].product_id,
                  serial_no: data[i].serial_no,
                  eq_model: data[i].eq_model,
                  location: data[i].location,
                  product_type: data[i].product_type,
                  project_price: data[i].project_price,
                  has_warranty: data[i].has_warranty,
                  warranty: data[i].warranty,
                  description: data[i].description,
                  rate: data[i].rate,
                  threshold_price: data[i].threshold_price,
                  quantity: data[i].quantity,
                  uom: data[i].uom,
                  total: data[i].total,
                  visit_barcode: data[i].visit_barcode,
                  is_optional: data[i].is_optional,
                });
              }

              this.$nextTick(() => {
                this.dbLineItems = filter(result, function (row) {
                  return (
                    row.product_type == "goods" || row.product_type == "service"
                  );
                });
                this.dbEquipments = filter(result, {
                  product_type: "equipment",
                });
              });
            });
          }

          if (!isEmpty(data.options)) {
            this.barcodeSetting = data.options;
          }

          if (!isEmpty(data.quotation)) {
            this.setQuotationData(data.quotation);
          }

          if (!isEmpty(data.invoice)) {
            this.setInvoiceData(data.invoice);
          }

          if (!isEmpty(data.customer)) {
            this.customer = data.customer;
            this.jobCustomer = this.customer.id;
          }

          if (!isEmpty(data.billing)) {
            this.billing = data.billing;
            this.jobBilling = this.billing.id;
          }

          if (!isEmpty(data.property)) {
            this.property = data.property;
            this.jobProperty = this.property.id;
          }

          if (!isEmpty(data.property_person)) {
            this.property_contact_person = data.property_person;
            this.jobCreate.attention =
              this.property_contact_person.display_name;
            this.jobPropertyPerson = this.property_contact_person.id;
          }

          if (!isEmpty(data.billing_person)) {
            this.billing_contact_person = data.billing_person;
            this.jobBillingPerson = this.billing_contact_person.id;
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },

    createJobSchedule({ job, schedule, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            isEmpty(schedule) === false &&
            moment(schedule.start_date).isValid()
          ) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit",
                data: {
                  start_date: schedule.start_date
                    ? moment(schedule.start_date).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD"),
                  start_time: schedule.start_time
                    ? moment(schedule.start_time, ["h:mm A"]).format("HH:mm")
                    : moment().startOf("day").format("HH:mm"),
                  end_date: schedule.end_date
                    ? moment(schedule.end_date).format("YYYY-MM-DD")
                    : moment(schedule.start_date).format("YYYY-MM-DD"),
                  /* end_time: schedule.end_time
                    ? moment(schedule.end_time, ["h:mm A"]).format("HH:mm")
                    : moment().endOf("day").format("HH:mm"), */
                  end_time: schedule.end_time
                    ? moment(schedule.end_time, ["h:mm A"]).format("HH:mm")
                    : moment("23:45", ["HH:mm"]).format("HH:mm"),
                  email: team.email_team,
                  tags: _this.response_schedule.tags,
                  visit: _this.visitId > 0 ? _this.visitId : null,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Invalid Start Date in Schedule.");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    assignTeam({ job, visit, team, teamId }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (isArray(team) && team.length > 0) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit/" + visit + "/engineer",
                data: {
                  engineer: team,
                  teamId: teamId,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    assignRecurringTeam({ job, team, teamId }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (isArray(team) && team.length > 0) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/allocate",
                data: {
                  engineer: team,
                  teamId: teamId,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    createRecurringSchedule({ job, pattern, dates, schedule }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          const recurrenceData = pattern[pattern.type];
          let formData = new Object();
          switch (pattern.type) {
            case "daily": {
              formData = {
                day_count: toSafeInteger(recurrenceData.day_count),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.response_schedule.start_date,
                end_date: _this.response_schedule.end_date,
                start_time: _this.response_schedule.start_time,
                end_time: _this.response_schedule.end_time,
                recurring_pattern: toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "weekly": {
              formData = {
                weeks: isArray(recurrenceData.weeks)
                  ? recurrenceData.weeks.join(",")
                  : null,
                week_count: toSafeInteger(recurrenceData.week_count),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.response_schedule.start_date,
                end_date: _this.response_schedule.end_date,
                start_time: _this.response_schedule.start_time,
                end_time: _this.response_schedule.end_time,
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "monthly": {
              formData = {
                week_day: toSafeInteger(recurrenceData.week_day),
                day_count: toSafeInteger(recurrenceData.day_count),
                week_count: toSafeInteger(recurrenceData.week_count),
                month_count:
                  toSafeInteger(recurrenceData.recurring_pattern) === 1
                    ? toSafeInteger(recurrenceData.day_month_count)
                    : toSafeInteger(recurrenceData.month_count),
                recurring_pattern: toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.response_schedule.start_date,
                end_date: _this.response_schedule.end_date,
                start_time: _this.response_schedule.start_time,
                end_time: _this.response_schedule.end_time,
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
          }

          if (!pattern.type) {
            reject("Invalid Request");
          }

          _this.$store
            .dispatch(PUT, {
              url: "job/" + job + "/" + pattern.type + "-recurrence",
              data: formData,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mounted() {
    this.getOptions();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Visit", route: "visit" },
      { title: "Create" },
    ]);

    JobScheduleTeamEventBus.$on("update:assigned-team", (argument, teamId) => {
      this.assigned_engineers = argument;
      this.teamId = teamId;
      // console.log(this.teamId, "heff");
    });

    JobScheduleTeamEventBus.$on("update:schedule-later", (argument) => {
      this.jobCreate.schedule_later = +argument;
    });

    JobEventBus.$on("update:one-off-schedule", (argument) => {
      this.one_off_schedule = argument;
    });

    JobEventBus.$on("update:recurring-pattern", (argument) => {
      this.recurring_pattern = argument;
    });

    JobEventBus.$on("update:recurring-schedule", (argument) => {
      this.recurring_schedule = argument;
    });

    JobEventBus.$on("update:recurring-dates", (argument) => {
      this.jobCreate.total_occurence = argument.length;
      this.recurring_dates = argument;
    });

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      this.terms_condition = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      this.note_attachments = argument;
    });

    JobEventBus.$on("update:show-calendar", () => {
      this.show_calendar = !this.show_calendar;
    });

    JobScheduleTeamEventBus.$on("get:schedule-team", (argument) => {
      try {
        this.response_schedule = {
          start_date: argument.start_date
            ? moment(argument.start_date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          end_date: argument.end_date
            ? moment(argument.end_date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          start_time: argument.start_time
            ? moment(argument.start_time, ["h:mm A"]).format("HH:mm")
            : moment().startOf("day").format("HH:mm"),
          end_time: argument.end_time
            ? moment(argument.end_time, ["h:mm A"]).format("HH:mm")
            : moment().endOf("day").format("HH:mm"),
        };
      } catch (error) {
        this.logError(error);
      }
    });
  },
  beforeMount() {
    this.visitId = toSafeInteger(this.$route.params.id);
    this.ticketId = toSafeInteger(this.$route.query.job);
    this.duplicatejob = toSafeInteger(this.$route.query.duplicate);
    //this.jobId = toSafeInteger(this.$route.params.id);
    this.jobCustomer = toSafeInteger(this.$route.query.customer);
    this.jobBilling = toSafeInteger(this.$route.query.billing);
    this.jobProperty = toSafeInteger(this.$route.query.property);
    this.jobPropertyPerson = toSafeInteger(this.$route.query.property_person);
    this.jobBillingPerson = toSafeInteger(this.$route.query.billing_person);
    this.quotationId = toSafeInteger(this.$route.query.quotation);
    this.invoiceId = toSafeInteger(this.$route.query.invoice);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.jobCreated && _this.lineItemCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
